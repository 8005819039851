<div *ngIf="currentPost" class="edit-form mt-4">
    <h4>Posztok</h4>
    <form>
        <div class="form-group">
            <label for="title">Cím</label>
            <input type="text" class="form-control" id="title" [(ngModel)]="currentPost.title" name="title" />
        </div>
        <div class="form-group">
            <label for="image">Kép</label>
            <input type="text" class="form-control" id="image" [(ngModel)]="currentPost.image" name="image" />
        </div>
        <div class="form-group">
            <label for="description">Leírás</label>
            <input type="text" class="form-control" id="description" [(ngModel)]="currentPost.description"
                name="description" />
        </div>
        <div class="form-group hidden">
            <input type="text" class="form-control" id="description" [(ngModel)]="currentPost.createdAt"
                name="createdAt" />
        </div>
        <div class="form-group">
            <label for="content">Content</label>
            <ejs-richtexteditor class="form-control" #content id='iframeRTE' [toolbarSettings]='tools' [iframeSettings]='iframe' [height]='height' [fontFamily] ='fontFamily' [(ngModel)]="currentPost.content" name="content"></ejs-richtexteditor>
        </div>

        <div class="form-group">
            <label><strong>Status:</strong></label>
            {{ currentPost.published ? "Published" : "Pending" }}
        </div>
    </form>

    <button class="badge badge-primary mr-2" *ngIf="currentPost.published">
        UnPublish
    </button>
    <button *ngIf="!currentPost.published" class="badge badge-primary mr-2">
        Publish
    </button>

    <button class="badge badge-danger mr-2" (click)="deletePost()">
        Delete
    </button>

    <button type="submit" class="badge badge-success" (click)="updatePost()">
        Update
    </button>
    <p>{{ message }}</p>
</div>

<div *ngIf="!currentPost">
    <br />
    <p>Cannot access this Tutorial...</p>
</div>
