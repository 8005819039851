<div *ngIf="currentPlant" class="edit-form">
    <h4>Palánták</h4>
    <form>
        <div class="form-group">
            <label for="category">Kép url</label>
            <input type="text" class="form-control" id="img" [(ngModel)]="currentPlant.img" name="img" />
        </div>
        <div class="form-group">
            <label for="category">Kategória</label>
            <input type="text" class="form-control" id="category" [(ngModel)]="currentPlant.category" name="category" />
        </div>
        <div class="form-group">
            <label for="species">Fajta</label>
            <input type="text" class="form-control" id="species" [(ngModel)]="currentPlant.species" name="species" />
        </div>
        <div class="form-group">
            <label for="description">Leírás</label>
            <input type="text" class="form-control" id="description" [(ngModel)]="currentPlant.description" name="description" />
        </div>

        <div class="form-group">
            <label><strong>Status:</strong></label> {{ currentPlant.available ? "Készleten" : "Elfogyott" }}
        </div>
    </form>

    <button class="badge badge-primary mr-2" *ngIf="currentPlant.available" (click)="updatePublished(false)">
        UnPublish
    </button>
    <button *ngIf="!currentPlant.available" class="badge badge-primary mr-2" (click)="updatePublished(true)">
        Publish
    </button>

    <button class="badge badge-danger mr-2" (click)="deletePlant()">
        Delete
    </button>

    <button type="submit" class="badge badge-success" (click)="updatePlant()">
        Update
    </button>
    <p>{{ message }}</p>
</div>

<div *ngIf="!currentPlant">
    <br />
    <p>Cannot access this Tutorial...</p>
</div>