<div class="tm-container mx-auto mt-5">
    <div class="order">
        <!-- <h1 class="banner" style="color: white;"> A 2022-es palántarendelés lezárult! <br> Ha bármi kérdésed van, keress bizalommal a <a href="mailto: kiskert.m@gmail.com">kiskert.m@gmail.com</a> email címen.<br>
        </h1> -->
        <h1 class="banner" style="color: white;">Elindult a palántarendelés a 2024-es szezonra, a böngészéshez kattints
            <a [routerLink]="['/plants']" [queryParams]="{ category: 'összes'}">IDE!</a></h1>
    </div>
    <section class="tm-section tm-section-1">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="tm-bg-circle-white tm-flex-center-v shadow">
                        <header class="text-center">
                            <h1 class="tm-site-title">Termelj Te is</h1>
                            <p class="tm-site-subtitle">saját kertedben vagy balkonodon,</p>
                        </header>
                        <p class="hiszen">hiszen nincs jobb érzés, mint azt az asztalra tenni, amiben saját kezünk munkája van.</p>
                        <p class="text-center mt-4 mb-0">

                        </p>

                    </div>
                </div>
            </div>
        </div>
    </section>
    <section id="tm-section-2" class="tm-section pt-2 pb-2">
        <div class="container">
            <div class="row">
                <div class="col-xl-5 col-lg-6 tm-flex-center-v tm-text-container tm-section-left">
                    <h2 class="tm-color-secondary mb-4">Ehhez ajánlom a Kiskert-M palántákat.</h2>
                    <p class="mb-4 font"><a [routerLink]="['/plants']" [queryParams]="{ category: 'összes'}">Széleskörű kínálatomban</a> biztosan megtalálod a számodra alkalmas fajtákat, legyen szó paradicsomról, paprikáról, tökfélékről, káposztafélékről, salátákról, uborkáról,
                        padlizsánról, zellerről, kelfélékről és fűszernövényekről.</p>
                    <p class="mb-5 font"> A hagyományos fajták mellett különlegességeket is találsz, amivel biztosan elkápráztatod a családot.</p>
                    <p class="text-right mb-0">

                    </p>
                </div>
                <div class="col-xl-7 col-lg-6 tm-circle-img-container">
                    <img src="./assets/pal1.jpg" alt="Image" class="rounded-circle tm-circle-img shadow">
                </div>
            </div>
        </div>
    </section>
    <section id="tm-section-3" class="tm-section tm-section-3">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 tm-section-2-right">
                    <div class="tm-bg-circle-white tm-bg-circle-pad-2 tm-flex-center-v shadow">
                        <header>
                            <h2 class="tm-color-primary">Élj a lehetőséggel</h2>
                        </header>
                        <p class="font">és ismerkedj meg új zöldségekkel is egyenesen a saját kertedből. Ettél már kínai kelt, pak-choyt vagy a konyhaművészetben közkedvelt pagoda karfiolt?</p>
                        <p class="font">A kiválasztott palántákat a feltüntetett településeken belül házhoz is szállítom május 20-25. között.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="tm-section pt-2 pb-3">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-lg-6 tm-flex-center-v tm-section-left">
                    <header>
                        <h2 class="tm-color-primary">Palántarendelés</h2>
                        <p class="font">Foglalási igényedet a kiválasztott fajták kosárba helyezésével tudod leadni. A darabszámokat a rendelés véglegesítése előtt, a kosárban tudod beállítani. Ha mindent kiválasztottál amit szeretnél a kertedbe, add meg a kuponkódodat
                            ha van, hogy érvényesíthessem a kedvezményedet, válaszd ki a listából a palánták átvételének módját és véglegesítsd a megrendelést.</p>
                        <p class="font">Ezután e-mailben kapni fogsz egy összesítőt. Ha ez nem érkezik meg 24 órán belül, kérlek vedd fel velem a kapcsolatot, mert akkor bizonyára valami technikai probléma akadt. A palánták növekedését nyomon követheted egészen a fólia
                            előkészítésétől a Kiskert-M <a href="https://www.facebook.com/kiskert.m">Facebook</a> és <a href="https://www.instagram.com/kiskert_m/">Instagram</a> oldalon vagy ha feliratkozol <a role="button" data-toggle="modal" data-target="#myModal"
                                href="">hírlevelemre</a>.</p>
                        <p class="font">Kérlek, csak a szállításra megjelölt településekről adj le rendelést vagy akkor, ha személyesen érte tudsz jönni Hódmezővásárhelyre. Postázás a palánták sérülékenysége miatt sajnos nem megoldható. Ha bármi kérdésed van, keress
                            bizalommal az oldal alján található elérhetőségeim egyikén!
                        </p>
                    </header>
                </div>
                <div class="col-xl-6 col-lg-6 tm-circle-img-container tm-contact-right">
                    <img src="./assets/Pal2-2.jpg" alt="Image" class="rounded-circle tm-circle-img shadow">
                </div>
            </div>
        </div>
    </section>
    <section id="tm-section-4" class="tm-section tm-section-4">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 tm-section-2-right">
                    <div class="tm-bg-circle-white tm-bg-circle-pad-2 tm-flex-center-v shadow">
                        <header>
                            <h2 class="tm-color-primary">Átvétel</h2>
                        </header>
                        <p class="font">A palánták átvételére május 20-26. között lesz lehetőség, akár szállítással kérted, akár te jössz el a rendelésedért.</p>
                        <p>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="tm-section pt-2 pb-3">
        <div class="container">
            <div class="row">
                <div class="col-xl-4 col-lg-4 tm-flex-center-v tm-section-left">
                    <header>
                        <p class="font"><strong>Hódmezővásárhelyen és Kishomokon</strong> a palánták kiszállítási ára – rendelési mennyiségtől függetlenül – 500 forint/cím.</p>
                        <p class="font"><strong>1. szállítási útvonal (1000 forint/cím):</strong> Algyő – Szeged – Újszeged – Deszk – Klárafalva – Kiszombor – Makó – Óföldeák – Földeák
                        </p>
                        <p class="font"><strong>2. szállítási útvonal (1000 forint/cím):</strong> Mártély – Mindszent – Baks – Csanytelek – Ópusztaszer – Kistelek – Balástya – Sándorfalva <br>
                        </p>
                        <p class="font"><strong>3. szállítási útvonal (1000 forint/cím):</strong> Székkutas – Kakasszék – Orosháza – Nagymágocs – Szentes – Derekegyház
                        </p>
                        <p class="font">
                            <strong>Személyes átvétel:</strong> palántáidat kérheted kiszállítás nélkül is, ebben az esetben Hódmezővásárhelyen, a Tücsök utcán veheted majd át (Új-Kishomok)
                        </p>
                    </header>
                </div>
                <div class="col-xl-8 col-lg-8 tm-circle-img-container tm-contact-right">
                    <img src="./assets/Pal3-2.jpg" alt="Image" class="rounded-circle tm-circle-img shadow">
                </div>
            </div>
        </div>
    </section>
    <section id="tm-section-5" class="tm-section tm-section-5">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 tm-section-2-right">
                    <div class="tm-bg-circle-white tm-bg-circle-pad-2 tm-flex-center-v shadow">
                        <header>

                        </header>
                        <p class="font">Palántavásárlásra előrendelés nélkül is lesz lehetőség, ebben az esetben viszont nem tudom garantálni a teljes kínálatot. Előrendelés nélküli vásárlásra május 31-június 2. között lesz lehetőség Hódmezővásárhelyen, a Tücsök utcán
                            (Új-Kishomok).
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="tm-section pt-2 pb-3">
        <div class="container">
            <div class="row">
                <div class="col-xl-4 col-lg-4 tm-flex-center-v tm-section-left">
                    <header>
                        <h2 class="tm-color-primary">Fizetés</h2>
                        <p class="font">Fizetni csak a palánták átvételekor kell, amit készpénzben vagy bankkártyás fizetéssel is megtehetsz.</p>
                        <p class="font">A palánták kínálatának változtatásának jogát fenntartom, melyről minden esetben egyeztetek a rendelővel. Minimum előrendelési mennyiség: 10 darab.</p>
                    </header>
                </div>
                <div class="col-xl-8 col-lg-8 tm-circle-img-container tm-contact-right">
                    <img src="./assets/pal6.jpg" alt="Image" class="rounded-circle tm-circle-img shadow">
                </div>
            </div>
        </div>
    </section>
</div>