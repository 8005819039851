<div class="list row">
    <div class="col">
        <h4>Bejegyzések</h4>
        <ul class="list-group">
            <li class="list-group-item" *ngFor="let post of posts; let i = index"
                [class.active]="i == currentIndex" (click)="setActivePost(post, i)">
                {{ post.title }}
            </li>
        </ul>
    </div>
</div>
<div class="col">
    <div *ngIf="currentPost">
        <app-post-details (refreshList)="refreshList()" [post]="currentPost"></app-post-details>
    </div>

    <div *ngIf="!currentPost">
        <br />
        <p>Kattints az egyikre</p>
    </div>
</div>
