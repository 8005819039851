<html>

<head>
    <script>
        (function(i, s, o, g, r, a, m) {
            i['GoogleAnalyticsObject'] = r;
            i[r] = i[r] || function() {
                (i[r].q = i[r].q || []).push(arguments)
            }, i[r].l = 1 * new Date();
            a = s.createElement(o),
                m = s.getElementsByTagName(o)[0];
            a.async = 1;
            a.src = g;
            m.parentNode.insertBefore(a, m)
        })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

        ga('create', 'UA-G-B5EJ7FF1G8', 'auto'); // Change the UA-ID to the one you got from Google Analytics
    </script>
    <script id="mcjs">
        ! function(c, h, i, m, p) {
            m = c.createElement(h), p = c.getElementsByTagName(h)[0], m.async = 1, m.src = i, p.parentNode.insertBefore(m, p)
        }(document, "script", "https://chimpstatic.com/mcjs-connected/js/users/b584f9942ba804448f38a21c7/68528f8443efd56f64d71041b.js");
    </script>
</head>
<app-header *ngIf="isAdminRoute()"></app-header>
<router-outlet></router-outlet>
<app-footer *ngIf="isAdminRoute()"></app-footer>

</html>