<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="#">
        <img src="./assets/logo-web.png" alt="logo" class="logo">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item">
                <a class="nav-link" href="#" routerLink="/admin/dashboard/kamra">
                    Kamra
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#" routerLink="/admin/dashboard/plants">
                    Palánták
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#">Megrendelések</a>
            </li>
        </ul>
        <form class="form-inline my-2 my-lg-0">
            <input class="form-control mr-sm-2" type="search" placeholder="" aria-label="Search">
            <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Keresés</button>
        </form>
        <button class="btn btn-default my-2 my-sm-0" type="submit" (click)="signOut()">Kijelentkezés</button>
    </div>
</nav>