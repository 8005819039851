<app-popup [message]="message" [title]="title" [type]="type" *ngIf="error" (close)="error = false;"></app-popup>
<div class="margin container-fluid mt-3 mx-auto">
    <div class="card-group">
        <div class="col col-md-4 mt-4" *ngFor="let kamra of categoryKamra;">
            <div class="card profile-card-5 shadow">
                <div class="card-img-block">
                    <img class="card-img-top" src="{{kamra.img}}" alt="Card image cap">
                </div>
                <div class="card-body pt-0">
                    <h4 class="card-title">{{kamra.title}}</h4>
                    <p class="bold"><b class="bold">Összetevők:</b></p>
                    <p class="card-text">{{kamra.description}}</p>
                    <p class="card-text"><b class="bold">Kiszerelés:</b> {{kamra.ml}}</p>
                </div>
                <div class="card-footer">
                    <div class="table-responsive">
                        <table class="table">
                            <tr>
                                <!-- <td><strong>Ár:</strong></td> -->
                                <td *ngIf="kamra.original_price" style="text-align: center;" class="price-tag"><strong><s> {{(kamra.original_price | currency:'HUF':'':'1.0')!.replace(',','.')}} Ft/db</s> <br> <span class="out-of-stock">{{(kamra.price | currency:'HUF':'':'1.0')!.replace(',','.')}} Ft/db</span></strong></td>
                                <td *ngIf="!kamra.original_price" style="text-align: center;" class="price-tag"><strong> {{(kamra.price | currency:'HUF':'':'1.0')!.replace(',','.')}} Ft/db</strong></td>
                                <td style="text-align: right;">
                                    <button data-dismiss="alert" class="btn btn-primary rounded-pill" (click)="addToCart(kamra)" [disabled]="!kamra.available"><span class="tocart"><i class="material-icons">&#xe854;</i>Kosárba</span></button>
                                </td>
                            </tr>
                            <tr>
                                <td *ngIf="kamra.available" class="stock">
                                    <strong>Elérhető:</strong> {{kamra.stock}} db
                                </td>
                                <td *ngIf="!kamra.available" class="stock">
                                    <strong>Elérhető:</strong> <span class="out-of-stock"> nincs készleten</span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>