<div class="submit-form">
    <div *ngIf="!submitted">
        <div class="form-group">
            <label for="title">Cím</label>
            <input type="text" class="form-control" id="title" required [(ngModel)]="post.title" name="title" />
        </div>

        <div class="form-group">
            <label for="description">Rövid leírás</label>
            <input class="form-control" id="description" required [(ngModel)]="post.description"
                name="description" />
        </div>

        <div class="form-group">
            <label for="image">Kép URL</label>
            <input class="form-control" id="image" required [(ngModel)]="post.image"
                name="image" />
        </div>

        <div class="form-group">
            <label for="content">Content</label>
            <ejs-richtexteditor class="form-control" #content id='iframeRTE'[toolbarSettings]='tools' [iframeSettings]='iframe' [height]='height' [fontFamily] ='fontFamily' [(ngModel)]="post.content"></ejs-richtexteditor>
        </div>

        <button (click)="savePost()" class="btn btn-success">Küldés</button>
    </div>

    <div *ngIf="submitted">
        <h4>Sikeres feltöltés!</h4>
        <button class="btn btn-success" (click)="newPost()">Új</button>
    </div>
</div>
