export class Plant {
    id?: string;
    img?: string;
    category?: string;
    species?: string;
    description?: string;
    price?: number;
    stock?: number;
    available?: boolean;
    quantity?: number;
    szalas?: boolean;
    total?: number;
}
