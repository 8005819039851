<div class="tm-container mx-auto mt-5">
    <section class="tm-section tm-section-1">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="tm-bg-circle-white tm-flex-center-v shadow">
                        <header class="text-center">
                            <h1 class="tm-site-title">Töltsd fel a kamrád</h1>
                            <p class="tm-site-subtitle">tartósítószer-és adalékanyagmentes </p>
                        </header>
                        <p class="hiszen">termékekkel a Kiskert-M kínálatából!</p>
                        <p class="text-center mt-4 mb-0">

                        </p>

                    </div>
                </div>
            </div>
        </div>
    </section>
    <section id="tm-section-2" class="tm-section pt-2 pb-2">
        <div class="container">
            <div class="row">
                <div class="col-xl-5 col-lg-6 tm-flex-center-v tm-text-container tm-section-left">
                    <h2 class="tm-color-secondary mb-4">Válogass folyamatosan bővülő kínálatomból </h2>
                    <p class="mb-4 font"> és vidd haza a nyár ízeit. <a [routerLink]="['/kamra']" [queryParams]="{ category: 'szörp'}">Szörpjeim</a> és <a [routerLink]="['/kamra']" [queryParams]="{ category: 'ivólé'}">rostos leveim</a> frissítően hatnak, kevés cukorral készült,
                        savanykás ízvilágú <a [routerLink]="['/kamra']" [queryParams]="{ category: 'lekvár'}">lekvárjaim</a> pedig visszaadják a gyümölcsök zamatát.</p>
                    <p class="mb-5 font"> A <a [routerLink]="['/kamra']" [queryParams]="{ category: 'zöldségkrémek'}">zöldségkrémek</a> segítenek tökéletesen indítani a reggelt egy finom pirítós társaságában, <a [routerLink]="['/kamra']" [queryParams]="{ category: 'egyéb'}">sült teáim</a>                        pedig felmelegítik a téli estéket. </p>
                    <p class="text-right mb-0">

                    </p>
                </div>
                <div class="col-xl-7 col-lg-6 tm-circle-img-container">
                    <img src="./assets/kamra/Kiskert-M_2022_39.jpg" alt="Image" class="rounded-circle tm-circle-img shadow">
                </div>
            </div>
        </div>
    </section>
    <section id="tm-section-3" class="tm-section tm-section-3">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 tm-section-2-right">
                    <div class="tm-bg-circle-white tm-bg-circle-pad-2 tm-flex-center-v shadow">
                        <header>
                            <h2 class="tm-color-primary">Különlegességeket is találsz </h2>
                        </header>
                        <p class="font">mint például a közönségkedvenc <a [routerLink]="['/kamra']" [queryParams]="{ category: 'egyéb'}">fügemustár</a> vagy a <a [routerLink]="['/kamra']" [queryParams]="{ category: 'egyéb'}">szilvás ketchup</a>, amiket mindenképp érdemes
                            megkóstolni.
                        </p>
                        <!-- <p class="font">A kiválasztott palántákat a feltüntetett településeken belül házhoz is szállítom május 2-3. hetében (kivéve a külön más időponttal jelzett fajtákat!). -->
                        <!-- </p> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="tm-section pt-2 pb-3">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-lg-6 tm-flex-center-v tm-section-left">
                    <header>
                        <h2 class="tm-color-primary">Rendelés menete</h2>
                        <div class="font order-steps">
                            <ol class="font">
                                <li>
                                    Böngészd át a <a [routerLink]="['/kamra']" [queryParams]="{ category: 'összes'}">kamra menüpontban</a> folyamatosan bővülő termékeimet és tedd kosárba a szimpatikus termékeket. (Darabszámot a kosárban tudsz beállítani
                                    a készlet erejéig.)
                                </li>
                                <li>
                                    Ha van kuponkódod, ne felejtsd el megadni!
                                </li>
                                <li>
                                    A következő fülön válaszd ki az átvétel módját és helyszínét.
                                </li>
                                <li>
                                    Töltsd ki a rendeléshez szükséges adataidat, hogy el tudjalak érni.
                                </li>
                                <li>
                                    Kattints a Megrendelés gombra és várd a finom falatokat! 😊
                                </li>
                            </ol>
                        </div>
                    </header>
                </div>
                <div class="col-xl-6 col-lg-6 tm-circle-img-container tm-contact-right">
                    <img src="./assets/kamra/Kiskert-M_2022_34.jpg" alt="Image" class="rounded-circle tm-circle-img shadow">
                </div>
            </div>
        </div>
    </section>
    <section id="tm-section-4" class="tm-section tm-section-4">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 tm-section-2-right">
                    <div class="tm-bg-circle-white tm-bg-circle-pad-2 tm-flex-center-v shadow">
                        <header>
                            <h2 class="tm-color-primary">Fizetés</h2>
                        </header>
                        <p class="font">Fizetni személyes átvételkor készpénzben vagy bankkártyával is tudsz, illetve banki átutalással is megoldható.</p>
                        <p>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="tm-section pt-2 pb-3">
        <div class="container">
            <div class="row">
                <div class="col-xl-4 col-lg-4 tm-flex-center-v tm-section-left">
                    <header>
                        <h2 class="tm-color-primary">Hogyan jutnak el hozzád a termékek? </h2>
                        <p class="font">A megrendelt termékeket személyesen átveheted Hódmezővásárhelyen előre egyeztetett időpontban a Klauzál vagy a Tücsök utcán, illetve Szegeden minden péntek délelőtt a Vértónál. </p>
                        <p class="font">Ha házhoz szállítással szeretnéd kérni, annak ára Hódmezővásárhelyen belül <strong>500 forint/cím</strong>, Szegeden <strong>800 forint/cím</strong>. Ha ezektől eltérő településre szeretnéd, azt a rendelés során tudod jelezni és
                            megbeszéljük a legjobb megoldást.
                        </p>
                    </header>
                </div>
                <div class="col-xl-8 col-lg-8 tm-circle-img-container tm-contact-right">
                    <img src="./assets/kamra/Kiskert-M_2022_30.jpg" alt="Image" class="rounded-circle tm-circle-img shadow">
                </div>
            </div>
        </div>
    </section>
</div>