import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kamra-order',
  templateUrl: './kamra-order.component.html',
  styleUrls: ['./kamra-order.component.scss']
})
export class KamraOrderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
