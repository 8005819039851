<div class="submit-form">
    <div *ngIf="!submitted">
        <div class="form-group">
            <label for="categorí">Kategória:</label>
            <select class="form-control" id="category" required [(ngModel)]="kamra.category" name="category">
                <option value="lekvár">Lekvár</option>
                <option value="szörp">Szörp</option>
                <option value="zöldségkrémek">Zöldségkrémek</option>
                <option value="száritottfüszerek">Szárított fűszerek</option>
                <option value="ivólé">Ivólé</option>
                <option value="egyéb">Egyéb</option>
            </select>
            <!-- <input type="text" class="form-control" id="category" required [(ngModel)]="plant.category" name="category" /> -->
        </div>

        <div class="form-group">
            <label for="url">Kép URL:</label>
            <input type="text" class="form-control" id="url" required [(ngModel)]="kamra.img" name="url" />
        </div>

        <div class="form-group">
            <label for="title">Termék:</label>
            <input type="text" class="form-control" id="title" required [(ngModel)]="kamra.title" name="title" />
        </div>

        <div class="form-group">
            <label for="description">Leírás:</label>
            <input type="text" class="form-control" id="description" required [(ngModel)]="kamra.description" name="description" />
        </div>

        <div class="form-group">
            <label for="description">Kiszerelés:</label>
            <input type="text" class="form-control" id="ml" required [(ngModel)]="kamra.ml" name="ml" />
        </div>

        <div class="form-group">
            <label for="price">Ár:</label>
            <input type="text" class="form-control" id="price"  [(ngModel)]="kamra.price" name="price" />
        </div>

        <button (click)="saveKamra()" class="btn btn-success">Submit</button>
    </div>

    <div *ngIf="submitted">
        <h4>You submitted successfully!</h4>
        <button class="btn btn-success" (click)="newKamra()">Add</button>
    </div>
</div>
