<div class="container-fluid mt-5 mx-auto">
    <div class="row">
        <div class="col-12">
            <article class="blog-card">
                <div class="blog-card__background shadow border-secondary">
                    <div class="card__background--wrapper">
                        <div class="card__background--main" style="background-image: url(./assets/palantarendeles.png);">
                            <div class="card__background--layer"></div>
                        </div>
                    </div>
                </div>
                <div class="blog-card__head">
                </div>
                <div class="blog-card__info">
                    <h2 class="tm-color-primary">Elindult a 2024-es szezonra a palántarendelés!</h2>
                    <p>Újult erővel térünk vissza 2024-ben, melynek első mérföldköve a tavaly sokatok által hiányolt Kiskert-M palánták! Több, mint 50 féle palánta közül válogathattok - de vigyázat, a készletek nem végtelenek, így a rendeléseteket érdemes
                        időben leadni.
                    </p>
                    <a href="#" [routerLink]="['/plants']" [queryParams]="{ category: 'összes'}" class="btn btn--with-icon"><i class="btn-icon fa fa-long-arrow-right"></i>Palántát szeretnék!</a>
                </div>
            </article>
        </div>
    </div>
</div>

<div class="container-fluid mt-3 mx-auto">
    <div class="row">
        <div class="col-md-6">
            <article class="blog-card">
                <div class="blog-card__background shadow">
                    <div class="card__background--wrapper">
                        <div class="card__background--main" style="background-image: url(./assets/fooldal2.jpg);">
                            <div class="card__background--layer"></div>
                        </div>
                    </div>
                </div>
                <div class="blog-card__head">
                </div>
                <div class="blog-card__info">
                    <h2 class="tm-color-primary">Figyeld a Kiskert-M blogot!</h2>
                    <p>A 2022-es szezonnal végre elindul a Kiskert-M blog is, ahol sok érdekes témát járok körbe és a kérdéseitekre is választ adok. Ha jól szétnézel, talán még kedvezmények is várnak rád?!</p>
                    <a href="#" class="btn btn--with-icon" [routerLink]="['/blog']"><i class="btn-icon fa fa-long-arrow-right"></i>BELEOLVASOK</a>
                </div>
            </article>
        </div>
        <div class="col-md-6 space mb-4">
            <article class="blog-card">
                <div class="blog-card__background shadow">
                    <div class="card__background--wrapper">
                        <div class="card__background--main" style="background-image: url(./assets/fooldal3.jpg);">
                            <div class="card__background--layer"></div>
                        </div>
                    </div>
                </div>
                <div class="blog-card__head">
                </div>
                <div class="blog-card__info">
                    <h2 class="tm-color-primary">Iratkozz fel hírlevelemre!</h2>
                    <p>Ne maradj le az aktuális tennivalókról, tippekről, a Kiskert-M történéseiről és a feliratkozóknak járó akciókról, iratkozz fel hírlevelemre most!<br> &nbsp;
                    </p>
                    <a href="#" class="btn btn--with-icon" data-toggle="modal" data-target="#myModal"><i class="btn-icon fa fa-long-arrow-right"></i>FELIRATKOZOM</a>
                </div>
            </article>
        </div>
    </div>
</div>

<section class="tm-section pt-2 pb-3">
    <div class="container">
        <div class="row">
            <div class="col-xl-4 col-lg-4 tm-flex-center-v tm-section-left">
                <header>
                    <h2 class="tm-color-primary">Nagy Petra Mercédesz vagyok, a Kiskert-M vegyszermentes termelője.</h2>
                    <p>Vidékfejlesztési agrármérnökként és dísznövénykertészként végeztem. Tanulmányaim hatására találtam rá az utamra, ami amellett, hogy értéket teremt vásárlóim számára, óvja és gyarapítja környezetünket.
                    </p>
                </header>
            </div>
            <div class="col-xl-8 col-lg-8 tm-circle-img-container tm-contact-right">
                <img src="./assets/bem1.jpg" alt="Image" class="rounded-circle tm-circle-img shadow">
            </div>
        </div>
    </div>
</section>
<section id="tm-section-4" class="tm-section tm-section-4">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 tm-section-2-right">
                <div class="tm-bg-circle-white tm-bg-circle-pad-2 tm-flex-center-v shadow">
                    <header>
                        <p>Kertem 100%-ban vegyszer- és műtrágyamentes, a palántáktól a zöldségeken át a virágokig, minden. Törekszem a fenntarthatóságra, aminek része, hogy a környezetemben található „gaznövényeket” és a letermett haszonnövényeket is újrahasznosítom
                            a kert gyarapítása érdekében.</p>
                        <p>
                            Célom, hogy az ökológiai gazdálkodás alapelvei mentén egy olyan gazdaságot hozzak létre, ami 40-50 környékbeli családot képes ellátni állandó jelleggel szezonális terménnyel.
                        </p>
                    </header>


                </div>
            </div>
        </div>
    </div>
</section>
<section class="tm-section pt-2 pb-3">
    <div class="container">
        <div class="row">
            <div class="col-xl-4 col-lg-4 tm-flex-center-v tm-section-left">
                <header>
                    <p>Rohanó életünkben fontosnak tartom, hogy megtanuljunk minél több időt szeretteinkre, magunkra és kikapcsolódásra, feltöltődésre fordítani, ezért a zöldségdobozok házhozszállításával szeretném megkönnyíteni ezt vásárlóim számára.</p>
                </header>
            </div>
            <div class="col-xl-8 col-lg-8 tm-circle-img-container tm-contact-right">
                <img src="./assets/bem51.jpg" alt="Image" class="rounded-circle tm-circle-img shadow">
            </div>
        </div>
    </div>
</section>
<section id="tm-section-5" class="tm-section tm-section-5">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 tm-section-2-right">
                <div class="tm-bg-circle-white tm-bg-circle-pad-2 tm-flex-center-v shadow">
                    <header>

                    </header>
                    <p>Közösségi felületeimen igyekszem minél többet átadni a tudásomból, közelebb hozni az embereket a természet és a kert szeretetéhez, emellett folyamatosan informálom a követőimet a kert történéseiről. Fontos számomra, hogy vásárlóimnak
                        lehetősége legyen látni, honnan jön az, ami az asztalukra kerül, ezért kertem előzetes egyeztetés után személyesen is meglátogatható.</p>
                </div>
            </div>
        </div>
    </div>
</section>