<div class="tm-container mx-auto mt-5">
    <!-- <section class="tm-section tm-section-1">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="tm-bg-circle-white tm-flex-center-v shadow">
                        <header class="text-center">
                                <h1 class="tm-site-title">HÁZHOZSZÁLLÍTÁST!</h1>
                                <p class="font">Ha legalább egy kérdésre IGEN a válasz,</p>
                                <p class="tm-site-subtitle">imádni fogod a</p>
                                <h1 class="doboz">ZÖLDSÉGDOBOZ</h1>
                                <h1 class="doboz">HÁZHOZSZÁLLÍTÁST!</h1>
                        </header>
                        <p class="hiszen">mint azzal, hogy a bevásárlást intézed?</p>
                        <p class="text-center mt-4 mb-0">
                            
                        </p>
  
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <section id="tm-section-2" class="tm-section pt-2 pb-2">
        <div class="container">
            <div class="row">
                <div class="col-xl-5 col-lg-6 tm-flex-center-v tm-text-container tm-section-left">
                    <!-- <h2 class="tm-color-secondary mb-4">Szívesebben töltöd a nyarat a strandon, esetleg a hűvös szobában, mint azzal, hogy a bevásárlást
                        intézed?</h2> -->
                    <p class="mb-4 font">Szívesebben töltöd a nyarat a strandon, esetleg a hűvös szobában, mint azzal, hogy a bevásárlást intézed?
                    </p>
                    <p class="mb-5 font">A sok dolog mellett csak teher kitalálni hogy mit kéne vásárolni és főzni?</p>
                    <p class="mb-5 font">Fontos számodra az egészséges, változatos étkezés és tudni, hogy mi kerül a család asztalára?</p>
                    <p class="text-right mb-0">

                    </p>
                </div>
                <div class="col-xl-7 col-lg-6 tm-circle-img-container">
                    <img src="./assets/vegbox11.jpg" alt="Image" class="rounded-circle tm-circle-img shadow">
                </div>
            </div>
        </div>
    </section>
    <section id="tm-section-3" class="tm-section tm-section-3">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 tm-section-2-right">
                    <div class="tm-bg-circle-white tm-flex-center-v shadow">
                        <header class="text-center">
                            <p class="font">Ha legalább egy kérdésre IGEN a válasz,</p>
                            <p class="tm-site-subtitle">imádni fogod a</p>
                            <h1 class="tm-site-title">ZÖLDSÉGDOBOZ</h1>
                            <h1 class="tm-site-title">HÁZHOZ- </h1>
                            <h1 class="tm-site-title">SZÁLLÍTÁST!</h1>
                        </header>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="bg mt-5">
        <h2 id="title">Ezek közül választhatsz: </h2>
        <h2 class="order">JELENLEG NEM RENDELHETŐ! Első 2024-as szállítás várhatóan májusban.</h2>
        <div class="hover-table-layout">
            <div class="listing-item">
                <figure class="image">
                    <img src="./assets/kisdoboz.jpg" alt="image">
                    <figcaption>
                        <div class="caption">
                            <h1>Kis doboz</h1>
                            <p>4500 Ft/db</p>
                            <p class="szallitas">Az itt felsorolt tartalom példa egy tavalyi dobozra.</p>
                        </div>
                    </figcaption>
                </figure>
                <div class="listing">
                    <h4>🧅 Vöröshagyma - 3 db</h4>
                    <h4>🥒 Cukkini (kb. 1-1.5 kg) - 1 db</h4>
                    <h4>🥕 Bébirépa - 12db</h4>
                    <h4>🥔 Újburgonya - 1 kg</h4>
                    <h4>🥬 Zöldhagyma - 1 csomó</h4>
                    <h4>🌱 Friss mentaválogatás - 1 csomó</h4>
                    <h4>🍒 Választható meggylekvár (314 ml) vagy <br> meggyszörp(500 ml) vagy ezek cukormentes változatai +200 Ft-ért </h4>
                </div>
            </div>
            <div class="listing-item">
                <figure class="image">
                    <img src="./assets/vegbox33.jpg" alt="image">
                    <figcaption>
                        <div class="caption">
                            <h1>Nagy doboz</h1>
                            <p>6000 Ft/db</p>
                            <p class="szallitas">Az itt felsorolt tartalom példa egy tavalyi dobozra.</p>
                        </div>
                    </figcaption>
                </figure>
                <div class="listing">
                    <h4>🧅 Vöröshagyma - 5 db</h4>
                    <h4>🥒 Cukkini (kb. 1-1.5 kg) - 1 db</h4>
                    <h4>🥕 Bébirépa - 20 db</h4>
                    <h4>🥔 Újburgonya - 1 kg</h4>
                    <h4>🥔 Parázskrumpli - 0,5 kg</h4>
                    <h4>🥬 Zöldhagyma - 1 csomó</h4>
                    <h4>🟣 Cékla - 3 db</h4>
                    <h4>🌱 Friss mentaválogatás - 1 csomó</h4>
                    <h4>🌱 Friss csombor (borsikafű) - 1 csomó</h4>
                    <h4>🍒 Cseresznye - 0,5 kg</h4>
                    <h4>🥮 Sajtos tallér (házi) - 0,2 kg</h4>
                    <h4>🍒 Választható meggylekvár (314 ml) vagy <br> meggyszörp(500 ml) vagy ezek cukormentes változatai +200 Ft-ért </h4>
                </div>
            </div>
            <!-- <div class="listing-item">
                <figure class="image">
                    <img src="https://kollektivmagazin.hu/wp-content/uploads/2020/06/marisol-casben-QvkAQTNj4zk-unsplash-1024x682.jpg" alt="image">
                    <figcaption>
                        <div class="caption">
                            <h1>EXTRA Doboz</h1>
                            <p>6000 Ft/db</p>
                        </div>
                    </figcaption>
                </figure>
                <div class="listing">
                    <h4>valami</h4>
                    <h4>valami</h4>
                    <h4>valami</h4>
                    <h4>valami</h4>
                    <h4>valami</h4>
                </div>
            </div> -->
        </div>
    </div>
    <div class="text-center caption">* FONTOS! A dobozok tartalmából elvenni nem tudsz, de pluszt kérhetsz hozzá saját ízlésed szerint. *</div>
    <section class="tm-section padding pb-3">
        <div class="container">
            <div class="row">
                <div class="col-xl-4 col-lg-4 tm-flex-center-v tm-section-left">
                    <header>
                        <p class="font">Vegyszermentes gazdaságomból származó szezonális zöldségeimből összeállítok hetente változó ajánlatot, amit egyenesen az ajtódig szállítok. A dobozokat rendszeresen kiegészítem általam készített tartósítószer- és adalékanyagmentes
                            termékekkel (szörpök, lekvárok, ivólevek, zöldségkrémek, stb.), sós süteményekkel, házi tésztákkal, tojással és terveim szerint hamarosan házi kenyerekkel is.</p>
                    </header>
                </div>
                <div class="col-xl-8 col-lg-8 tm-circle-img-container tm-contact-right">
                    <img src="./assets/vegbox44.jpg" alt="Image" class="rounded-circle tm-circle-img shadow">
                </div>
            </div>
        </div>
    </section>
    <section id="tm-section-4" class="tm-section tm-section-4">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 tm-section-2-right">
                    <div class="tm-bg-circle-white tm-bg-circle-pad-2 tm-flex-center-v shadow">
                        <header>
                            <h2 class="tm-color-primary">Mi kerül a dobozokba?</h2>
                        </header>
                        <p class="font">A kínálat hetente változó, attól függően, hogy éppen milyen finomságok érnek a kertben. Amiben biztos lehetsz, hogy minden amit kapsz, vegyszermentes és szívem-lelkem benne van egészen a magvetéstől a szüretelésig.</p>
                        <p>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="tm-section pt-2 pb-3">
        <div class="container">
            <div class="row">
                <div class="col-xl-4 col-lg-4 tm-flex-center-v tm-section-left">
                    <header>
                        <h2 class="tm-color-primary">Hol tudom átvenni a rendelt dobozomat?</h2>
                        <p class="font"><strong>Hódmezővásárhelyen</strong> és <strong>Kishomokon</strong> belül INGYEN házhozszállítom a rendelésedet.</p>
                        <p class="font">Ha <strong>Algyőn</strong> vagy <strong>Szegeden</strong> laksz, akkor is lehetőséged van tőlem vásárolni – de ebben az esetben számolnod kell a szállítási költséggel is, mivel sajnos az én autóm is üzemanyaggal megy. 😊</p>
                        <p class="font">
                            Algyőre és Szegedre 1000 forint/cím a kiszállítás költsége.
                        </p>
                    </header>
                </div>
                <div class="col-xl-8 col-lg-8 tm-circle-img-container tm-contact-right">
                    <img src="./assets/vegbox66.jpg" alt="Image" class="rounded-circle tm-circle-img shadow">
                </div>
            </div>
        </div>
    </section>
    <section id="tm-section-5" class="tm-section tm-section-5">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 tm-section-2-right">
                    <div class="tm-bg-circle-white tm-bg-circle-pad-2 tm-flex-center-v shadow">
                        <header>
                            <h2 class="tm-color-primary">Hogyan tudok rendelni?</h2>
                        </header>
                        <p class="font">Rendelésedet leadhatod a Kiskert-M <a href="https://www.facebook.com/kiskert.m/">Facebook</a> vagy <a href="https://www.instagram.com/kiskert_m/">Instagram</a> oldalán privát üzenetben, a +36309635178 telefonszámon vagy az oldal
                            alján található űrlap kitöltésével. <br> Ha nem szeretnél lemaradni a zöldségdobozok indulásáról, <a href="" data-toggle="modal" data-target="#myModal">iratkozz fel</a> hírlevelemre!</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="tm-section pt-2 pb-3">
        <div class="container">
            <div class="row">
                <div class="col-xl-4 col-lg-4 tm-flex-center-v tm-section-left">
                    <header>
                        <h2 class="tm-color-primary">Mikor és hogyan kell fizetnem?</h2>
                        <p class="font">Fizetésre lehetőség van készpénzben a rendelésed átvételekor vagy banki átutalással a kiszállítást megelőző 2 napon belül.</p>
                    </header>
                </div>
                <div class="col-xl-8 col-lg-8 tm-circle-img-container tm-contact-right">
                    <img src="./assets/vegbox88.jpg" alt="Image" class="rounded-circle tm-circle-img shadow">
                </div>
            </div>
        </div>
    </section>
</div>
<div>
    <div class="margin">
        <h2 class="tm-color-primary text-center">Add le rendelésedet itt:</h2>
        <form class="form-group mx-auto" [formGroup]="form" method="post" (ngSubmit)="onSubmit()">
            <label for="name" class="font">Név</label>
            <input type="text" class="form-control" id="name" [formControl]="name" required>
            <label for="phone" class="font">Telefonszám</label>
            <input type="text" class="form-control" id="phone" [formControl]="phone">
            <label for="email" class="font">Email</label>
            <input type="email" class="form-control" id="email" [formControl]="email" placeholder="email@example.com" required>
            <label for="message" class="font">Rendelésed</label>
            <textarea id="message" class="form-control" [formControl]="message" placeholder="Ide írd rendelésed tartalmát..." required></textarea>
            <button [disabled]="isLoading" class="btn btn-success" type="submit">Küldés</button>
            <input [formControl]="honeypot" class="hidden" type="text" />
            <div [ngClass]="!submitted? 'hidden' : 'visible'" class="center-text">
                <span>{{responseMessage}}</span>
            </div>
        </form>
    </div>