<div class="margin container-fluid mt-3 mx-auto">
    <div class="card-group">
        <div class="col col-md-4 mt-4" *ngFor="let item of items;">
            <div class="card profile-card-5 shadow">
                <div class="card-img-block">
                    <img class="card-img-top" src="{{item.img}}" alt="Card image cap">
                </div>
                <div class="card-body pt-0">
                    <h4 class="card-title">{{item.species}} ({{item.szalas ? 'szálas' : 'cserepes'}})</h4>
                    <p class="card-text">{{item.description}}</p>
                </div>
                <div class="card-footer">
                    <ng-container>
                        <div class="table-responsive">
                            <table class="table">
                                <tr>
                                    <td class="price-tag"><strong *ngIf="item.price"> {{(item.price | currency:'HUF':'':'1.0')!.replace(',','.')}} Ft/db</strong></td>
                                    <td class="price-tag" *ngIf="item.available">
                                        <strong>Készlet: {{(item.stock | currency:'HUF':'':'1.0')?.replace(',','.')}} db </strong>
                                    </td>
                                    <td class="price-tag" *ngIf="!item.available">
                                        <strong>Készlet: nincs készleten </strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="text-align: center;">
                                        <button *ngIf="item.title" type="button" class="btn btn-primary rounded-pill" data-toggle="modal" data-target="#exampleModal" (click)="setCurrentItem(item)">
                                            Módosítás
                                        </button>
                                        <button *ngIf="item.species" type="button" class="btn btn-primary rounded-pill" data-toggle="modal" data-target="#palantakModal" (click)="setCurrentItem(item)">
                                            Módosítás
                                        </button>
                                    </td>
                                    <td style="text-align: center;">
                                        <button data-dismiss="alert" class="btn btn-primary rounded-pill">Törlés</button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
<app-dialog [currentItem]="currentItem"></app-dialog>
<app-dialog-palantak [currentItem]="currentItem" [editMode]="true"></app-dialog-palantak>