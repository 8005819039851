<app-popup [message]="message" [title]="title" [type]="type" *ngIf="error" (close)="error = false;"></app-popup>
<div class="margin container-fluid mt-3 mx-auto">
    <div class="card-group">
        <div class="col col-md-4 mt-4" *ngFor="let plant of categoryPlants;">
            <div class="card profile-card-5 shadow" *ngIf="plant.price">
                <div class="card-img-block">
                    <img class="card-img-top" src="{{plant.img}}" alt="Card image cap">
                </div>
                <div class="card-body pt-0">
                    <h4 class="card-title">{{plant.species}} ({{plant.szalas ? 'szálas' : 'cserepes'}})</h4>
                    <p class="card-text">{{plant.description}}</p>
                </div>
                <div class="card-footer">
                    <ng-container>
                        <div class="table-responsive">
                            <table class="table">
                                <tr>
                                    <td style="text-align: right;" class="price-tag"><strong> {{(plant.price | currency:'HUF':'':'1.0')!.replace(',','.')}} Ft/db</strong></td>
                                    <td style="text-align: right;">
                                        <button data-dismiss="alert" class="btn btn-primary rounded-pill" (click)="addToCart(plant)" [disabled]="!plant.available"><span class="tocart"><i class="material-icons">&#xe854;</i>Kosárba</span></button>
                                    </td>
                                </tr>
                                <tr>
                                    <td *ngIf="plant.available" class="stock">
                                        <strong>Elérhető:</strong> {{plant.stock}} db
                                    </td>
                                    <td *ngIf="!plant.available" class="stock">
                                        <strong>Elérhető:</strong> <span class="out-of-stock"> nincs készleten</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>